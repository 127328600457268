const translation = {
  header: {
    links: {
      home: 'Home',
      about: 'About us',
      contact: 'Contact',
      services: 'Services',
    }
  },
  hero: {
    title: 'Informatic solutions to your needs',
    description: 'We analyze, plan and materialize the best IT solution for your project.',
  },
  contact: {
    title: 'Send us a message!',
    description_01: "We'll answer you as soon as we can :).",
    description_02: "If you prefer, you can write us directly to our e-mail inbox:",
    urqu_email: 'contact@urqu.tech',
    full_name: {
      label: 'Full name',
      placeholder: 'Your name and lastname'
    },
    email: {
      label: 'E-mail',
      placeholder: 'email@example.com'
    },
    message: {
      label: 'Message',
      placeholder: 'Here goes your message',
    },
    send: 'Send message'
  },
  bullets: {
    title: 'Our Reason: Mission, Vision, and Values',
    item_01: {
      title: 'Mission',
      description: 'We aim to make the world a simpler place by creating and designing intuitive and inclusive solutions that help humanity take the next step.',
    },
    item_02: {
      title: 'Vision',
      description: 'We want to transform the lives of our clients by providing them with the tools they need to optimize their time and improve their performance.',
    },
    item_03: {
      title: 'Values',
      description: {
        bullet_01: 'We evolve towards simplicity.',
        bullet_02: 'We grow together as a team.',
        bullet_03: 'We visualize the goal before bringing the code to life.',
        bullet_04: 'We build with an intuitive focus.',
        bullet_05: 'We share and multiply knowledge.',
        bullet_06: 'We prioritize people.',
      }
    },
  },  
  our_team: {
    title: 'Meet the URQU-TECH founders',
    description_01: 'At URQU, we strongly believe in our roots.',
    description_02: 'From our origin, surrounded by mountains, we strive to bring the best of what we have to offer to the world.',
    description_03: 'The combination of our experiences in various fields provides us with the opportunity to enhance our skills and deliver high-quality products that make a difference to our clients.',
    founder_01: {
      name: 'Ale Luna Bringas',
      position: 'DevOps, SRE Engineer',
      description: "Alejandro, an SRE (Site Reliability Engineer), brings the DevOps culture to every project he leads. With extensive experience in automation and Cloud technologies, he excels in crafting robust, secure, and automated architectures. His background as a Telecommunications Engineer allows him to deeply analyze security and networking needs, delivering strong and secure solutions both in Cloud and On-Premise environments.",
    },
    founder_02: {
      name: 'Pili Chanampe',
      position: 'Full Stack Developer',
      description: "Pili is a skilled Full-Stack developer with extensive experience in UX/UI and Front-End, currently diving into the Back-End world. Her meticulous and creative approach shines through in every project she works on. In addition to her technical expertise, she is also a visual artist and holds a degree in Music, specializing in Classical Guitar. Her artistic background provides her with a unique perspective, enabling her to offer innovative solutions and proposals that add genuine value to every product she develops.",
    },
    founder_03: {
      name: 'Juank Serrano',
      position: 'Sales Manager',
      description: 'Juank, with over a decade of experience leading companies across diverse industries, seamlessly combines his expertise as a Certified Public Accountant with his innovative and inquisitive mindset. He has successfully introduced agile methodologies to non-tech industries, bringing a fresh perspective to the business world. Now, he brings his extensive experience from other industries to the IT realm, offering quality, focus, and a well-defined vision.',
    },        
  },
  about: {
    section_01: {
      title: 'What is URQU-TECH?',
      description_01: 'We are a development company founded and established in La Rioja, Argentina. From here, we aim to offer robust and scalable solutions, always focusing on simplicity in the use of our products.',
      description_02: 'For URQU, it is important to think and reflect on what we want to achieve and solve with each proposal. Our starting point is people: we want to help them focus on what they truly need, providing them with solutions that make their tasks more practical and enjoyable.',
      description_03: 'From the moment of analysis, planning, and materializing our projects, the goal is to create products designed to provide simple, intuitive, and easy-to-use experiences.',
    },
    section_02: {
      title: 'How did URQU-TECH start?',
      description_01: 'We are a development company founded and established in La Rioja, Argentina. From here, we aim to offer robust and scalable solutions, always focusing on simplicity in the use of our products.',
      description_02: 'For URQU, it is important to think and reflect on what we want to achieve and solve with each proposal. Our starting point is people: we want to help them focus on what they truly need, providing them with solutions that make their tasks more practical and enjoyable.',
      description_03: 'From the moment of analysis, planning, and materializing our projects, the goal is to create products designed to provide simple, intuitive, and easy-to-use experiences.',
    },
    section_03: {
      title: 'The Founders',
      founder_01: {
        name: 'Ale Luna Bringas',
        position: 'SRE/DevOps Engineer',
        description: 'Ale is an SRE (Site Reliability Engineer) who instills the DevOps culture in all the projects he participates in. He has extensive experience automating infrastructure, which has allowed him to lead various cloud projects, creating robust, secure, and scalable architectures. His background as a Telecommunications Engineer provides him with the necessary tools to thoroughly analyze security and networking needs in each solution.',
      },
      founder_02: {
        name: 'Pili Chanampe',
        position: 'Full Stack Developer',
        description: 'Pili holds a degree in Music (specialized in Classical Guitar) and is a visual artist. Her creative training, combined with her detailed focus, gives her a unique perspective, allowing her to offer innovative solutions and proposals that add genuine value to every product she develops. She has a Full-Stack profile with extensive experience in UX/UI and Front-End, optimizing code to create reusable components and architectures, aiming to generate increasingly simple and intuitive experiences.',
      },
      founder_03: {
        name: 'Juank Serrano',
        position: 'Sales/Finance/Operations',
        description: 'Juank has more than a decade of experience in managing companies across different industries. His background as a Certified Public Accountant, combined with his innovative and curious profile, has allowed him to develop his potential in managerial roles. He has successfully introduced agile methodologies to production plants, bringing a fresh perspective to the business world. Now, he brings all his experience to the tech field, offering quality, focus, and a clear vision.',
      },
    },
  },
  services: {
    title: 'The services we offer',
    description: 'In our service offering, we provide all the necessary tools to deliver end-to-end solutions. We offer DevOps options for efficient technology project management. Additionally, we provide mobile and web application development services, both on the Front-End and Back-End. We also offer UX/UI services to create intuitive and appealing user experiences.',
    service_01: {
      title: 'DevOps',
      bullets: {
        item_01: 'MultiCloud',
        item_02: 'Serverless',
        item_03: 'Consultancy',
      },      
      description: 'We offer specialized DevOps consultancy to provide efficient solutions to our clients. Leveraging our expertise in DevOps architecture and methodologies, we design and optimize cloud-based technology infrastructures, including MultiCloud environments. Additionally, we implement Serverless solutions to enhance scalability and operational efficiency. Our DevOps services cover everything from deployment automation to configuration management and monitoring, ensuring an agile and robust software development and deployment lifecycle.',
    },
    service_02: {
      title: 'Aplications',
      bullets: {
        item_01: 'Android/iOs',
        item_02: 'PWA',
        item_03: 'Desktop',
      },
      description: 'We provide cross-platform application development solutions that cater to multiple platforms and devices. We offer native application development solutions for Android and iOS, ensuring seamless and optimized experiences on mobile devices. Additionally, we provide Progressive Web Apps (PWA) that allow users to access highly functional web applications from any browser. Moreover, we offer desktop application development services, delivering software solutions for Windows operating systems.',
    },
    service_03: {
      title: 'Front-End',
      bullets: {
        item_01: 'Web Design',
        item_02: 'CSR/SSR',
        item_03: 'Performance',
      },
      description: "We bring Front-End services that deliver high-quality web experiences. We specialize in web design, creating attractive and functional interfaces to captivate users' attention. We implement rendering techniques both on the client-side (CSR) and server-side (SSR) to ensure optimal performance and fast page loading. Our Front-End solutions focus on design, user experience, and efficient performance.",
    },
    service_04: {
      title: 'Back-End',
      bullets: {
        item_01: 'Architecture',
        item_02: 'Business logic',
        item_03: 'Integrations',
      },
      description: 'We offer robust and reliable Back-End services to meet the needs of our clients. We carefully design our architecture to ensure efficient and scalable performance. We handle the implementation of business logic in applications, ensuring accurate execution of specific algorithms and rules. Additionally, we offer integrations with external services, enabling connection and collaboration with third-party systems to extend functionality and enhance user experience. Our Back-End services serve as a solid foundation that drives the operation and success of your applications.',
    },
    service_05: {
      title: 'UX / UI',
      bullets: {
        item_01: 'Design',
        item_02: 'Accessibility',
        item_03: 'Mobile first',
      },
      description: 'We prioritize delivering exceptional user experiences. Through design, we create attractive and functional interfaces, considering factors such as usability and visual aesthetics. Additionally, we focus on accessibility, ensuring that our interfaces are inclusive and can be used by people with diverse abilities. We also embrace the "Mobile first" methodology, prioritizing the mobile experience to ensure a design that is adapted and optimized for smaller screens.',
    },
  },
  footer: {
    terms_and_conditions: 'Terms and Conditions',
    privacy_policy: 'Privacy Policy',
  },
  email_success: {
    description_01: 'Your e-mail was sent successfully!',
    description_02: "We'll get back to you very soon."
  },
  common: {
    contact_us: 'Contact us',
    go_main_page: 'Go to main page',
    go_back: 'Go back',
    close: 'Close',
    show_more: 'Show more',
    more: 'More',
  },
  error: {
    message: "Oops! It seems we're having problems with our e-mail service.",
    try_again: 'Please, try again later.'
  }
}

export default translation